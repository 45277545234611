.fuzzy-content {
  grid-gap: 5px;
  grid-auto-flow: row;
  align-items: center;
  padding: 10px;
  display: grid;
  box-shadow: 0 3px 8px #0000003d;
}

.fuzzy-content > div:nth-child(1) {
  grid-template-columns: 1fr max-content;
  align-items: center;
  display: grid;
}

.fuzzy-wrap {
  margin-bottom: 1rem;
  padding: 5px;
}

.fuzzy-tags {
  float: left;
  margin: 0 10px 10px 0;
  position: relative;
}

.fuzzy-tags span:nth-child(1) {
  margin-top: -20px;
  margin-left: 4px;
  position: absolute;
}

/*# sourceMappingURL=index.65eefae1.css.map */
